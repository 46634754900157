import { type ReactNode } from 'react';
import { useExperiment } from '@hungryroot/experiments';
import WellnessSectionContent from '../components/WellnessSectionContent/WellnessSectionContent';

type ImageProp = {
  src: string;
  height: number;
  width: number;
  srcSet: string;
};

export type WellnessSectionProps = {
  defaultImg1Desktop: ImageProp;
  defaultImg1Mobile: ImageProp;
  defaultImg2Desktop: ImageProp;
  defaultImg2Mobile: ImageProp;
  variantImg1Desktop: ImageProp;
  variantImg1Mobile: ImageProp;
  variantImg2Desktop: ImageProp;
  variantImg2Mobile: ImageProp;
  variantImg3Desktop: ImageProp;
  variantImg3Mobile: ImageProp;
  variantImg4Desktop: ImageProp;
  variantImg4Mobile: ImageProp;
};

type Content = {
  heading: string;
  sections: ReactNode;
};

export function useWellnessSectionExperimentApi(
  defaultContent: Content,
  props: WellnessSectionProps
) {
  const variant = useExperiment(157);

  if (variant === undefined) {
    return;
  }

  if (variant < 1) {
    return {
      content: defaultContent,
      variant,
    };
  }

  return {
    content: {
      heading: 'The easiest way to eat healthy',
      sections: <WellnessSectionContent {...props} />,
    },
    variant,
  };
}
