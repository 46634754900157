import { useMedia } from 'react-use';
import { Heading, HeadingVariant } from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { type WellnessSectionProps } from '../../hooks/useWellnessSectionExperimentApi';
import styles from './WellnessSectionContent.module.css';

export default function WellnessSectionContent(props: WellnessSectionProps) {
  const {
    variantImg1Desktop,
    variantImg1Mobile,
    variantImg2Desktop,
    variantImg2Mobile,
    variantImg3Desktop,
    variantImg3Mobile,
    variantImg4Desktop,
    variantImg4Mobile,
  } = props;

  const isDesktop = useMedia(BreakpointQueryLarge);

  const br = isDesktop && <br />;

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Heading variant={HeadingVariant.H4} className={styles.heading}>
          <span className={styles['text-avocado']}>
            Save hours planning, shopping, and cooking.
          </span>{' '}
          <span className={styles['text-transparent']}>
            We'll recommend and deliver food you'll love.
          </span>
        </Heading>
        <picture>
          <source
            srcSet={variantImg1Desktop.src}
            media={BreakpointQueryLarge}
          />
          <img
            {...variantImg1Mobile}
            loading="lazy"
            className={styles.image}
            alt=""
          />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant={HeadingVariant.H4} className={styles.heading}>
          <span className={styles['text-avocado']}>Feel your best</span>{' '}
          <span className={styles['text-transparent']}>
            with nutritious recipes and groceries for every{br}taste, budget,
            and goal.
          </span>
        </Heading>
        <picture>
          <source
            srcSet={variantImg2Desktop.src}
            media={BreakpointQueryLarge}
          />
          <img
            {...variantImg2Mobile}
            loading="lazy"
            className={styles.image}
            alt=""
          />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant={HeadingVariant.H4} className={styles.heading}>
          <span className={styles['text-avocado']}>Discover new favorites</span>{' '}
          <span className={styles['text-transparent']}>
            with 15,000+ recipes, ready-to-eat{br}meals, and healthy groceries.
          </span>
        </Heading>
        <picture>
          <source
            srcSet={variantImg3Desktop.src}
            media={BreakpointQueryLarge}
          />
          <img
            {...variantImg3Mobile}
            loading="lazy"
            className={styles.image}
            alt=""
          />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant={HeadingVariant.H4} className={styles.heading}>
          <span className={styles['text-avocado']}>
            Save up to 40% each week
          </span>{' '}
          <span className={styles['text-transparent']}>
            by ditching{br}takeout and reducing food waste.
          </span>
        </Heading>
        <picture>
          <source
            srcSet={variantImg4Desktop.src}
            media={BreakpointQueryLarge}
          />
          <img
            {...variantImg4Mobile}
            loading="lazy"
            className={styles.image}
            alt=""
          />
        </picture>
      </div>
    </div>
  );
}
