import clsx from 'clsx';
import {
  useWellnessSectionExperimentApi,
  type WellnessSectionProps,
} from '@hungryroot/exp-157-homepage-redesign';
import { Heading, HeadingVariant } from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import {
  Variant as HomepageIconVariant,
  HomepageIcon,
} from '../../HomepageIcon';
import styles from './WellnessSection.module.css';

export function WellnessSection(props: WellnessSectionProps) {
  const {
    defaultImg1Desktop,
    defaultImg1Mobile,
    defaultImg2Desktop,
    defaultImg2Mobile,
  } = props;

  const defaultContent = {
    heading: 'Meet your partner in healthy living',
    sections: (
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <HomepageIcon variant={HomepageIconVariant.Sun} />
          </div>
          <Heading variant={HeadingVariant.H2}>
            <span className={styles['text-avocado']}>
              Save hours planning, shopping, and cooking.
            </span>{' '}
            <span className={styles['text-transparent']}>
              We deliver food you’ll{' '}
              <span className={styles.nowrap}>love + easy recipes.</span>
            </span>
          </Heading>
        </div>
        <div className={styles.item}>
          <picture>
            <source
              srcSet={defaultImg1Desktop.src}
              media={BreakpointQueryLarge}
            />
            <img
              {...defaultImg1Mobile}
              loading="lazy"
              className={styles.image}
              alt=""
            />
          </picture>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <HomepageIcon variant={HomepageIconVariant.Trophy} />
          </div>
          <Heading variant={HeadingVariant.H2}>
            <span className={styles['text-avocado']}>
              Achieve your goals on your terms.
            </span>{' '}
            <span className={styles['text-transparent']}>
              More veggies? Less sodium? We’ve{' '}
              <span className={styles.nowrap}>got you.</span>
            </span>
          </Heading>
        </div>
        <div className={styles.item}>
          <picture>
            <source
              srcSet={defaultImg2Desktop.src}
              media={BreakpointQueryLarge}
            />
            <img
              {...defaultImg2Mobile}
              loading="lazy"
              className={styles.image}
              alt=""
            />
          </picture>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <HomepageIcon variant={HomepageIconVariant.Bank} />
          </div>
          <Heading variant={HeadingVariant.H2}>
            <span className={styles['text-avocado']}>
              Save up to 40% each week
            </span>{' '}
            <span className={styles['text-transparent']}>
              by ditching takeout and reducing food waste.
            </span>
          </Heading>
        </div>
      </div>
    ),
  };

  const { content, variant } =
    useWellnessSectionExperimentApi(defaultContent, props) || {};

  if (content === undefined) {
    return;
  }

  const { heading, sections } = content;

  return (
    <div
      className={clsx(styles.outer, {
        [styles['outer-default']]: variant !== undefined && variant < 1,
        [styles['outer-variant']]: variant !== undefined && variant >= 1,
      })}
    >
      <section className={styles.container}>
        <div className={styles.heading}>
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <Heading
            variant={HeadingVariant.HeroAlt}
            className={styles['heading-text']}
          >
            {heading}
          </Heading>
        </div>
        {sections}
      </section>
    </div>
  );
}
